import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/";
import UserContext from "../../store/user/user-context";
import AddBottomBar from "../ui/mobile/BottomBar/Add";
import HomeBottomBar from "../ui/mobile/BottomBar/Home";
import ProfileBottomBar from "../ui/mobile/BottomBar/Profile";
// import SearchBottomBar from "../ui/mobile/BottomBar/Search";
import ShopBottomBar from "../ui/mobile/BottomBar/Shop";
import LikeIcon from "../ui/mobile/menu/like";
import Notification from "../ui/Notification/Notification";

const MobileBottomBar = () => {
    const userCtx = useContext(UserContext)
    const location = useLocation();
    const {t} = useTranslation();
    const {pathname} = location;

    const items = [
        {
            component: HomeBottomBar,
            title: "Home",
            path: t("routes.home")
        },
        // {
        //     component: SearchBottomBar,
        //     title: "Search",
        //     path: t("routes.search")
        // }, 
        // {
        //     component: Notification,
        //     title: "Like",
        //     path: t("routes.likes")
        // },
        {
            component: AddBottomBar,
            title: "Add",
            path: t("routes.addPost")
        },
        {
            component: ShopBottomBar,
            title: "Shop",
            path: t("routes.shop")
        },
        {
            component: ProfileBottomBar,
            title: "Profile",
            path: t("routes.profile")
        },
    ];

    
    useEffect(() => {
        return () => {}
    }, [location])

    // if(userCtx.user !== ""){
        return (
            <div className="block md:hidden">
                <ul className="fixed bottom-0 w-full bg-white p-2 flex flex-row space justify-between border-t z-50">
                    {items.map((item, index) => 
                    <li key={index}>
                        {item.component({
                            className: "flex flex-col items-center",
                            children: item.title,
                            active: pathname === item.path
                        })}</li>)}
                </ul>
            </div>
        );
    // } else {
    //     return (<></>)
    // }
}

export default MobileBottomBar;