import { createContext, useEffect, useState } from "react";
const LocalStorageContext = createContext({
    localstorage: null,
    loadLocalStorageUser: () => {},
    writeLocalStorageUser: () => {},
});

export function LocalStorageContextProvider(props) {
    const [localstorage, setLocalstorage] = useState(window.localStorage);

    

    function loadLocalStorageUser(){
        return localStorage.getItem('user')
    }
    
    function writeLocalStorageUser(account){
        return localStorage.setItem('user', account);
    }

    const context = {
        localstorage: localstorage,
        loadLocalStorageUser: loadLocalStorageUser,
        writeLocalStorageUser: writeLocalStorageUser
    };

    return (
        <LocalStorageContext.Provider value={context}>
          {props.children}
        </LocalStorageContext.Provider>
    );
};

export default LocalStorageContext;