import { useContext, useEffect } from "react";
import MenuMobileContext from "../../store/menu-mobile/menu-mobile-context";
import LeftBar from "./LeftBar";

const ViewWithSidebar = (props) => {
    const menuCtx = useContext(MenuMobileContext);
    useEffect(() => {}, [menuCtx])

    return(
        <>
            { props.children }
            <div className={ menuCtx.isOpen ? 
                "translate-x-0       transition ease-in-out duration-700 md:hidden w-full h-[100vh] md:h-max fixed top-0 z-50 bg-white" : 
                "translate-x-[100vw] transition ease-in-out duration-700 md:hidden w-full h-[100vh] md:h-max fixed top-0 z-50 bg-white"} >
                <LeftBar />
            </div>
    </>)
}

export default ViewWithSidebar;