import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ListLikes from "./ListLikes";

const PostActions = ({like, action, likes}) => {
    const [index, setIndex] = useState(0);
    const [open, setOpen] = useState(0);
    const {t} = useTranslation()

    useEffect(async() => {
        setIndex(like ? 1 : 0);
        return () => {};
    }, [like]);

    const hearth = [
        {
            fill: "none",
            stroke: "currentColor",
            path: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
        },
        {
            fill: "currentColor",
            stroke: "",
            color: "text-red-500",
            path:<path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
        }
    ];

    return (
        <>
            <div className="flex flex-row content-start items-center px-4 gap-2">
                <svg onClick={action} xmlns="http://www.w3.org/2000/svg" className={"h-7 w-7 "+ hearth[index].color} fill={hearth[index].fill} viewBox="0 0 24 24" stroke={hearth[index].stroke}>
                    {hearth[index].path}
                </svg>
                {open ? 
                   <ListLikes likes={likes} action={()=>setOpen(0)}/>
                    : ""
                }
                {likes && likes[0] ? 
                    <span className="font-bold">
                        <Link to={"/"+likes[0]}>{likes[0].substr(0, 5)}...{likes[0].substr(likes[0].length-5, 5)}</Link>
                        {likes.length > 1 ? <span className="cursor-pointer" onClick={()=>setOpen(1)}> {t("msg.altri")}</span> : "" }
                    </span>
                    : ""}
            </div>
        </>
    )
}

export default PostActions