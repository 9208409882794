import axios from "axios";
import { useContext, useEffect, useState } from "react"
// import { Link } from "react-router-dom";
import PostController from "../../store/posts/PostController";
import UserContext from "../../store/user/user-context";
import UserController from "../../store/user/UserController";
import PostPreviewWithPhoto from "./PostPreviewWithPhoto";
import PostPreviewWithOutPhoto from "./PostPreviewWithOutPhoto";
import EthereumNetworkContext from "../../store/ethereum/network";

const PostHome = (props) => {
    const [post, setPost] = useState({});
    const [user, setUser] = useState(null)
    const userCtx = useContext(UserContext);
    const ethereumNetworkCtx = useContext(EthereumNetworkContext);
    const postAddress = props.post;

    useEffect(() => {
        async function fetchData() {
            if(props.post !== undefined){
                const postContent = await new PostController().getPostFromAddress(postAddress);
                if(postContent.url!==''){
                    const response = await axios.get("https://socialsmartchain.digitalnext.business/ipfs/" + postContent.url)
                    postContent.url = ( response.data !== null && response.data !== "[object Object]" ) ? postContent.url : null;
                    // return response.data != null ? setPost(prevPost => postContent) : setPost(prevPost => null);
                    setPost(prevPost => postContent);
                } else {
                    postContent.url = null;
                    setPost(prevPost => postContent);
                }
            }
          }
          fetchData();
        return () => {}
    }, [postAddress, props.post])

    function printTime(timestamp){
        const dateObject = new Date(timestamp * 1000)
        const humanDateFormat = dateObject.toLocaleString() //2019-12-9 10:30:15
        return humanDateFormat;
    }

    useEffect(() => {
        async function fetchData() {
            if (post.owner !== undefined) {
                if ( post.owner.toLowerCase() === userCtx.user ) {
                    setUser(userCtx.data.username);
                } else {
                    // await ethereumNetworkCtx.makeCall(async ()=> {
                        setUser(await new UserController().getUser(post.owner));
                    // })
                }
            }
          }
          if (post.owner !== undefined) {
              fetchData();
          }
        return () => {}
    }, [post, ethereumNetworkCtx, userCtx])

    if (post !== {} && user !== null) {
        return (
            <div className={props.className}>
                {post.url ? 
                    <PostPreviewWithPhoto 
                            post={post}
                            address={postAddress}
                            time={printTime(post.timestamp)}
                            user={user}
                        /> :
                        <PostPreviewWithOutPhoto 
                            post={post}
                            address={postAddress}
                            time={printTime(post.timestamp)}
                            user={user}
                        /> 
                    }
            </div>
        );
    } else {
        //  border border-blue-300 shadow max-w-sm
        return (
            <div className="md:border w-full">
                <div className="flex flex-row items-center gap-4 px-4 my-2">
                    <div className="animate-pulse rounded-full bg-slate-700 h-10 w-10"></div>
                    <div className="basis-1/2">
                        <div className="animate-pulse bg-slate-700 flex space-x-4 h-2 rounded">
                    </div>
                    </div>
                </div>
                <div className="bg-slate-700 animate-pulse h-[341px] w-[100%]"></div>
                <div className="animate-pulse flex space-x-4 p-4">
                    <div className="flex-1 space-y-6 py-1">
                        <div className="h-2 bg-slate-700 rounded"></div>
                        <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-slate-700 rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PostHome;


/* <Link to={"/"+post.owner}>
                    <div className="px-4 my-2 nunito flex flex-row items-center gap-2">
                        <div className="rounded-full bg-slate-700 h-10 w-10"></div> {user != null ? (user.ens ? user.username+" | "+user.ens.name : user.username) : post.owner.substring(0, 15)+"..."}
                    </div>
                </Link>
                {post.url ? <img src={"https://socialsmartchain.digitalnext.business/ipfs/"+ post.url} className="w-full" /> : "" }
                <div className="mx-4">
                    <p className="my-2"><Link to={"/"+post.owner}><span className="font-bold">{user != null ? (user.ens ? user.username+" | "+user.ens.name : user.username) : post.owner.substring(0, 15)+"..."}</span></Link> {post.description}</p>
                    <p className="text-gray-600 font-bold text-sm">{printTime(post.timestamp)}</p>
</div> */