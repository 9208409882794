import './polyfill.js'
import ReactDOM from 'react-dom';
import './index.css';

import './i18n';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import { EthereumContextProvider } from './store/ethereum/ethereum.js';
import { EthereumNetworkContextProvider } from './store/ethereum/network.js';
import {UserContextProvider} from './store/user/user-context'


import Layout from "./components/layout/MainLayout";
import { LocalStorageContextProvider } from './store/localstorage/localstorage.js';
import OpenSeaController from './store/checkPermission/OpenSeaController.js';
import { PostContextProvider } from './store/posts/post-context.js';

ReactDOM.render(
    <EthereumContextProvider>
        <EthereumNetworkContextProvider>
            <LocalStorageContextProvider>
                <UserContextProvider>
                    <PostContextProvider>
                        <BrowserRouter>
                            <Layout>
                                <App />
                            </Layout>
                        </BrowserRouter>
                    </PostContextProvider>
                </UserContextProvider>
            </LocalStorageContextProvider>
         </EthereumNetworkContextProvider>
    </EthereumContextProvider>,    
  document.getElementById('root')
);
