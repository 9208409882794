import { ethers } from "ethers";
import { useContext, useEffect, useState } from "react";
import PostContext from "../../../store/posts/post-context";
import UserContext from "../../../store/user/user-context";
import LikeIcon from "../mobile/menu/like"
const jsonInterfacePostike = require("../../../abi/UserPostLike.sol/UserPostLike.json");

const Notification = ({children}) => {
    const postCtx = useContext(PostContext);
    const userCtx = useContext(UserContext);
    const contractAddress = jsonInterfacePostike["networks"][80001]["address"];
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const contract = new ethers.Contract(contractAddress, jsonInterfacePostike["abi"], provider);

    const [badge, setBadge] = useState(0);

    const handleEvent = (user, post) => {
        console.log(user, post)
        if (postCtx.posts.filter(item => item == post).length === 1){
            setBadge(previus => previus + 1);
        }
    }

    const resetCounter = () => {
        setBadge(0);
    }

    useEffect(() => {
        if (postCtx.posts.length > 0) {
            contract.on("SetLikeToPost", handleEvent)
        }
      return () => {
        contract.removeAllListeners("SetLikeToPost");
      };
    }, [userCtx.user, postCtx]);


    return (
        <>
            <div className="relative" onClick={resetCounter}>
                {badge > 0 ? 
                    <span className="absolute right-[-10px] top-[-10px] bg-red-300 bg-opacity-50 border border-red-700 w-4 text-center">
                        {badge}
                    </span> 
                    : '' 
                }
                <LikeIcon />
            </div>
            {children}
        </>
    )
}

export default Notification