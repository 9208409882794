import axios from "axios";

const Web3 = require("web3");
const constants = require("../../constants.json");
const jsonInterface = require("../../abi/ProfilePicture.sol/ProfilePicture.json");


class ProfilePictureController {
    web3Contract;
    contract;

    constructor() {
        let web3 = null;
        if (window.ethereum) {
            // const web3 = new Web3(window.web3.currentProvider);
            web3 = new Web3(window.ethereum);
        } else {
            web3 = new Web3("https://polygon-mumbai.g.alchemy.com/v2/Zl5jVUPWcKiBeiyiRQntX36WvNwBKDnK");
            
        }
        this.web3Contract = web3.eth.Contract;
        this.contract = new this.web3Contract(jsonInterface["abi"], jsonInterface["networks"][80001]["address"]);
    }

    async getMyProfilePicture(address){
        if(address!==""){
            const profilePicture = await this.contract.methods.getMyProfilePicture().call({from: address });
            return profilePicture;
        }
    }

    async getProfilePicture(address){
        if (address !== null) {
            const profilePicture = await this.contract.methods.getProfilePicture(address).call();
            return profilePicture;
        } else {
            return null;
        }
    }
    
    async setProfilePicture(photoHash, address){
        const gasAmount = await this.contract.methods.setProfilePicture(
            photoHash,
        ).estimateGas({from: address});
        var data = this.contract.methods.setProfilePicture(photoHash,).send({from: address, gas: gasAmount})
            .on('transactionHash', function(hash){
                // console.log(hash);
                // return hash;
            })
            .on('confirmation', function(confirmationNumber, receipt){
                // console.log(confirmationNumber);
                // console.log(receipt);
            })
            .on('receipt', function(receipt){
                // console.log({success: receipt});
            }).on('error', function(error, receipt) {
                // console.log(error);
            });
        return data;
    }

}

export default ProfilePictureController;

