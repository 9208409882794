import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";

function Footer() {
    const { t } = useTranslation();
    return (
        <footer className="hidden md:bg-purple-600 md:mt-4 md:block">
            <section className="py-4 bg-purple-700 flex flex-row justify-center gap-4">
                {/* <p className="text-5xl text-white font-bold opacity-50"><Link to={{pathname:"https://digitalnext.business"}} target="_blank">DigitalNext</Link></p> */}
            </section>
            <section className="container mx-auto py-4">
                <Link to="/"><img src="logo-white.png" className="w-28" alt="Sochain Logo"/></Link>
                {/* <form onSubmit={(event)=>event.preventDefault()} className="flex flex-row gap-4">
                    <input className="w-56 placeholder:italic placeholder:text-gray-400 block bg-white border border-gray-300 rounded-md py-2 pl-9 pr-3 shadow-sm sm:text-sm focus:outline-none focus:shadow-lg" placeholder="Search for anything..." type="text" name="search" />
                    <button className="border-2 px-2 rounded-md bg-white text-black active:text-white active:bg-black active:duration-200">Invia</button>
                </form> */}
            </section>
            <section className="container mx-auto">
                <hr className="border-white"/>
                <div className="flex flex-row justify-between py-6">
                    <div><p className="text-white text-sm">© 2021 - {new Date().getFullYear()} DigitalNext</p></div>
                    <div className="flex flex-row gap-4">
                        <p className="text-white text-sm"><Link to="#">Privacy Policy</Link></p>
                        <p className="text-white text-sm"><Link to="#">Terms of Service</Link></p>
                    </div>
                </div>
            </section>
        </footer>
    );
}

export default Footer;