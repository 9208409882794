import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MenuMobileContext from "../../store/menu-mobile/menu-mobile-context";
import HomeBottomBar from "../ui/mobile/BottomBar/Home";
import ProfileBottomBar from "../ui/mobile/BottomBar/Profile";
import ShopBottomBar from "../ui/mobile/BottomBar/Shop";
import CloseIcon from "../ui/mobile/menu/close";
import WalletIcon from "../ui/mobile/menu/credit-card";
import StepIcon from "../ui/mobile/menu/steps";
import EditIcon from "../ui/mobile/menu/edit";
import UserContext from "../../store/user/user-context";

const LeftBar = () => {
    const menuCtx = useContext(MenuMobileContext);
    const userCtx = useContext(UserContext);
    const {t} = useTranslation()

    return(
        <>
            <ul className="font-bold text-xl px-[10px] md:relative md:bg-transparent w-full h-[100vh]">
                <li className="md:hidden my-4 flex flex-row justify-between items-center" onClick={()=>menuCtx.closeMenu()}>
                    <Link to={"/"}>Sochain</Link> 
                    <span><CloseIcon onClick={()=> menuCtx.closeMenu()} /></span>
                </li>
                <li onClick={()=>menuCtx.closeMenu()}><HomeBottomBar className="my-4 flex flex-row items-center gap-2">Home</HomeBottomBar></li>
                <li onClick={()=>menuCtx.closeMenu()}><ShopBottomBar className="my-4 flex flex-row items-center gap-2">Nfts Marketplace</ShopBottomBar></li>
                <li onClick={()=>menuCtx.closeMenu()}><ProfileBottomBar className="my-4 flex flex-row items-center gap-2">{userCtx.user !== "" ? "Profile" : "Accedi"}</ProfileBottomBar></li>
                {userCtx.user !== "" ? 
                    <li onClick={()=>menuCtx.closeMenu()}><EditIcon path={t("routes.editProfile")} className="my-4 flex flex-row items-center gap-2">Edit Profile</EditIcon></li>
                    :
                    ""}
                <li onClick={()=>menuCtx.closeMenu()}><WalletIcon path={t("routes.wallet")} className="my-4 flex flex-row items-center gap-2">Wallet</WalletIcon></li>
                <hr />
                <li onClick={()=>menuCtx.closeMenu()}><StepIcon path={t("routes.steps")} className="my-4 flex flex-row items-center gap-2">Steps for use Sochain!</StepIcon></li>
            </ul>
        </>
    );
}

export default LeftBar;