import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import EthereumContext from "../../store/ethereum/ethereum";
import EthereumNetworkContext from "../../store/ethereum/network";
import UserContext from "../../store/user/user-context";
import WalletController from "../../store/wallet/walletController";
import PolygonIcon from "../../components/ui/icons/PolygonIcon";
// import RenderBarChart from "./RenderBarChart";
// import RenderLineChart from "./RenderLineChart";

const Wallet = () => {
    const [balance, setBalance] = useState("");
    const {t} = useTranslation()
    const userCtx = useContext(UserContext);
    const ethCtx = useContext(EthereumContext);
    const ethereumNetworkCtx = useContext(EthereumNetworkContext);
    // const walletController = new WalletController();

    const walletController = useMemo(() => new WalletController(), []);

    useEffect(() => {
        async function loadData(){
            if(userCtx.user) {
                ethereumNetworkCtx.makeCall(async() =>{
                    setBalance(await walletController.getBalance(userCtx.user));
                })
            } else {
                userCtx.login()
            }
        }
        loadData();
        return () => {};
    }, [ethCtx, ethereumNetworkCtx, userCtx, walletController]);
    

    return (
        <>
            <div className="mt-4">
                <h1 className="text-3xl font-bold text-center text-opacity-50">{t("wallet.title")}</h1>
                <h2 className="text-2xl font-bold text-opacity-50 flex flex-row justify-center items-center">{t("msg.balance")}: {balance / (10 ** 18) } <PolygonIcon className={"fill-purple-400"} /></h2>
                <h3 className="text-xl font-bold text-center text-opacity-50">{t("msg.askFounds")} <a className="text-purple-400" href="https://faucet.polygon.technology/" target="_blank" rel="noreferrer">Faucet Mumbai</a> !</h3>
            </div>
            <div className="h-16"></div>
            {/* <div className="container mx-auto flex flex-col md:flex-row">
                <section>
                    <RenderLineChart width={600} height={300} data={[
                        {name: 'Page A', uv: 0, pv: 2200, amt: 800},
                        {name: 'Page B', uv: 400, pv: 2400, amt: 2400},
                        {name: 'Page C', uv: 800, pv: 2600, amt: 2200},
                        {name: 'Page D', uv: 400, pv: 2400, amt: 2400},
                        {name: 'Page E', uv: 200, pv: 2200, amt: 2800},
                    ]} /> 
                </section>
                <section>
                    <RenderBarChart />
                </section>
            </div> */}
        </>
    );
}

export default Wallet;