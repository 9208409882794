import LeftBar from "./LeftBar"

const DesktopLayout = (props)=> {
    return (
        <div className="flex flex-row justify-center gap-4">
            <div className="hidden md:block md:h-96 md:w-[293px] md:mt-4 md:sticky md:top-[55pt]">
                <LeftBar />
            </div>
            <div className="w-full md:w-[614px] p-0 mt-4">
                {props.children}
            </div>
        </div>
    )
}

export default DesktopLayout;