import './App.css';
import Router from "./routes/Router";

const App = () => {
  return (
    <div className="App">
        <Router />
    </div>
  );
}

export default App;

