import { createContext, useCallback, useContext, useEffect, useState } from "react";
import UserContext from "../user/user-context";
import EthereumContext from "./ethereum";
const constants = require("../../constants.json");

const EthereumNetworkContext = createContext({
  network: false,
  changeNetwork: () => {},
  makeCall: () => {},
});



export function EthereumNetworkContextProvider(props) {
    const ethereumCtx = useContext(EthereumContext);
    const userCtx = useContext(UserContext);
    const [network, setNetwork] = useState(false);


    const changeNetwork = useCallback( async () =>{
      if (ethereumCtx) {
        try {
          await ethereumCtx.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x13881' }], // chainId must be in hexadecimal numbers
          });
          setNetwork(true);
        } catch(error) {
          if (error.code === 4902) {
            try {
              await ethereumCtx.request({
                method: 'wallet_addEthereumChain',
                params: [
                  constants.mumbai
                ],
              });
              setNetwork(true);
            } catch (addError) {
              console.error(addError);
            }
          }
        }
      }
    }, [ethereumCtx] );

  const makeCall = useCallback(async (callback) => {
    // await ethereumCtx.request({ method: 'eth_requestAccounts' });
    const chainId = await ethereumCtx.request({ method: 'eth_chainId' });
    if(chainId === "0x13881" ){
      setNetwork(chainId);
    } else {
      await changeNetwork();
    }
    if (network && callback) {
      callback();
    }
  }, [ethereumCtx, network, changeNetwork]);

    const context = {
      network: network,
      changeNetwork: changeNetwork,
      makeCall: makeCall
    };

    useEffect(() => {
      if (ethereumCtx) {
        ethereumCtx.on('chainChanged', (chainId) => {
          if(chainId !== "0x13881") {
            alert("Chain not supported");
            makeCall(()=>window.location.reload());
          } 
        });
      }
      return () => {};
    }, [ethereumCtx, makeCall]);
    
  
    return (
        <EthereumNetworkContext.Provider value={context}>
          {props.children}
        </EthereumNetworkContext.Provider>
    );
};

export default EthereumNetworkContext;