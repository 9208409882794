import { Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Home from '../pages/Home';
import NotFound from "../pages/NotFound";
import Profile from "../pages/Profile";
import EditProfile from "../pages/Profile/editProfile";
import Categories from "../pages/Categories";
import Post from "../pages/Post";
import Shop from "../pages/Shop/Shop";
import AddPost from "../pages/AddPost/AddPost";
import Nft from "../pages/Nft";
import Wallet from "../pages/Wallet";
import HomeWithOutMetamask from "../pages/Home/HomeWithOutMetamask";
import LikesList from "../pages/LikesList";

function Router () {
    const { t } = useTranslation();
    return (
        <Switch>
            <Route path='/' component={Home} exact />
            <Route path={t('routes.profile')} component={Profile}></Route>
            <Route path={t('routes.categories')} component={Categories}></Route>
            <Route path={t('routes.shop')} component={Shop}></Route>
            <Route path={t('routes.addPost')} component={AddPost}></Route>
            <Route path={t('routes.wallet')} component={Wallet}></Route>
            <Route path={t('routes.steps')} component={HomeWithOutMetamask}></Route>
            <Route path={t('routes.editProfile')} component={EditProfile}></Route>
            <Route path={t('routes.likes')} component={LikesList}></Route>
            <Route path={'/post/0x:address'} component={Post}></Route>
            <Route path={'/nft/:id'} component={Nft}></Route>
            <Route path={'/0x:address'} component={Profile}></Route>
            <Route component={NotFound} />
        </Switch>
    );
}

export default Router;