const translation = {
    'sitename': 'Sochain',
    'home': {
        'title': 'Benvenuto a SoChain',
        'subtitle': 'Il primo social basato sulla blockchain Ethereum!',
    },
    'profile': {
        title: "Profilo"
    },
    'publish': {
        title: "Pubblica"
    },
    'wallet': {
        title: "Portafoglio"
    },
    menu: {
        'home': 'Home',
        'categories': 'Categorie',
        'profile': 'Profilo'
    },
    routes: {
        'home': '/',
        'categories': '/categorie',
        'profile': '/profilo',
        'shop': '/negozio-nfts',
        'addPost': '/publish',
        'wallet': '/portafoglio',
        'steps': '/passaggi-per-usare-sochain',
        'editProfile': '/modifica-profilo',
        'likes': '/mi-piace',
    },
    msg: {
        "altri": "e altri",
        "balance": "Saldo",
        "askFounds": "Richiedi fondi su",
        "writePost": "Scrivi il tuo post!",
        "send": "Invia",
        "liked": "Mi piace",
        traits: "Tratti"
    }
}

export default translation;