import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import DesktopLayout from "../../components/layout/DesktopLayout";

const Nft = (props) =>{
    const {t} = useTranslation()
    const location = useLocation();

    const id = props.match.params.id;
    const { nft } = location.state ?? props.post

    return (
        <>
            <DesktopLayout>
                    {nft.collection.banner_image_url ? <img src={nft.collection.banner_image_url} alt={nft.collection.name}/> : ""}
                    <section className="p-4">
                        <h1 className="text-2xl">{nft.name}</h1>
                        <h2 className="text-md">{nft.collection.name}</h2>
                    </section>
                    <img src={nft.image_url} width="100%" />
                    <section className="flex flex-row items-center gap-2 p-4">
                        <img className="rounded-full w-8" src={nft.creator.profile_img_url} alt={"Profile image of"+ nft.creator.address} /><span className="text-xs"><Link to={"/"+nft.creator.address}>{nft.creator.address}</Link></span>
                    </section>
                    <section className="px-4">
                        <p>Owner: {nft.owner.user ? nft.owner.user.username : "NullAddress"}</p>
                        {/* <p className="text-sm">Address: {nft.owner.address}</p> */}
                    </section>
                    <section className="px-4 mt-2">
                        {nft.traits.length > 0 ? 
                            <h2 className="text-2xl">{t("msg.traits")}</h2> :
                            ""
                        }
                        <table className="w-full">
                            {nft.traits.map((item, key) => 
                                <tr>
                                    <td>{item.trait_type}</td>
                                    <td>{item.value}</td>
                                    <td>{item.trait_count}</td>
                                </tr>
                            )}
                        </table>
                    </section>
                    <div className="h-[20vw]"></div>
            </DesktopLayout>
        </>
    );

}

export default Nft;