import MainNavigation from './MainNavigation'
import Footer from './Footer'
import MobileBottomBar from './MobileBottomBar';
// import LeftBar from './LeftBar';
import { MenuMobileContextProvider } from '../../store/menu-mobile/menu-mobile-context';
import ViewWithSidebar from './ViewWithSidebar';
import { useContext } from 'react';
import UserContext from '../../store/user/user-context';


function MainLayout(props) {

    const userCtx = useContext(UserContext)

    return (
        <>
            <MenuMobileContextProvider>
                <ViewWithSidebar>
                    <MainNavigation />
                </ViewWithSidebar>
            </MenuMobileContextProvider>
            <div className='min-h-[90vh]'>
                {props.children}
            </div>
            {userCtx.user !== "" ? <MobileBottomBar /> : ""}
            <Footer />
        </>
    );
}
export default MainLayout;