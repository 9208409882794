import axios from "axios";

const Web3 = require("web3");
const constants = require("../../constants.json");
const jsonInterface = require("../../abi/PostFactory.sol/PostFactory.json");
const jsonInterfacePostike = require("../../abi/UserPostLike.sol/UserPostLike.json");

class PostController {
    web3Contract;
    contract;
    postLike;

    constructor() {
        let web3 = null;
        if (window.ethereum) {
            // const web3 = new Web3(window.web3.currentProvider);
            web3 = new Web3(window.ethereum);
        } else {
            web3 = new Web3("https://polygon-mumbai.g.alchemy.com/v2/Zl5jVUPWcKiBeiyiRQntX36WvNwBKDnK");
        }
        this.web3Contract = web3.eth.Contract;
        this.contract = new this.web3Contract(jsonInterface["abi"], jsonInterface["networks"][80001]["address"]);
        this.postLike = new this.web3Contract(jsonInterfacePostike["abi"], jsonInterfacePostike["networks"][80001]["address"]);
    }

    async getPostsHome(){
        console.log(this.contract);
        const posts = await this.contract.methods.getAllPosts().call();
        return posts;
    }

    async getPostsFromOwner(address){
        if (address !== null) {
            const posts = await this.contract.methods.getPostFromOwner(address).call();
            return posts;
        } else {
            return [];
        }
    }
    
    async getPostFromAddress(address){
        if (address !== null) {
            const postContent = await this.contract.methods.getPostFromAddress(address).call();
            const post = {
                url: postContent[0],
                description: postContent[1],
                timestamp: postContent[2],
                owner: postContent[3],
            }
            return post;
        }
    }
    
    async countPosts(address){
        if (address === null) {
            return 0;
        } else {
            return await this.contract.methods.countUserPost(address).call();
        }
    }

    async uploadPhoto(file){
        const formData = new FormData();
        formData.append("file", file);
        return await axios.post(constants.fileStorageURL + "/api/v0/add", formData);
    }

    async publishPost(postData, userCtx){
        // axios.post(constants.fileStorageURL + "/api/v0/add", formData,
        // ).then(async (response) => {
        let urlFile = "";
        const file = postData.file;
        const description = postData.description;
        if (file) {
            const response = await this.uploadPhoto(file);
            if (response.status === 200) {
                const filePublish = (response.data);
                urlFile = filePublish.Hash
            } else {
                throw 'Error in upload'; 
            }
        }
        try {
            this.contract.methods.createPost(
                urlFile,
                description != null? description : ""
            ).estimateGas({from: userCtx.user})
        } catch (error) {
            return false
        }
        
        try {
            const response = await this.contract.methods.createPost(
                urlFile,
                description != null? description: "").send(
                    {from: userCtx.user}
            );
            return true;
        } catch (error) {
            return false;
        }
    }

    async getUserPostLikePost(post, userAddress){
        let liked = false;
        try {
            liked = await this.postLike.methods.postUserLiked(post, userAddress).call()
        } catch (error) {
            console.log(error);
        }
        return liked;
    }
    async getPostLikeRecived(post){
        let liked = false;
        try {
            liked = await this.postLike.methods.getAllLiked(post).call()
        } catch (error) {
            console.log(error);
        }
        return liked;
    }

    async getUserPostLikePost(post, userAddress){
        let liked = false;
        try {
            liked = await this.postLike.methods.postUserLiked(post, userAddress).call()
        } catch (error) {
            console.log(error);
        }
        return liked;
    }
    
    async addLike(post, userAddress){
        const response = await this.postLike.methods.setLike(post).send({from: userAddress})
        return response;
    }
    async removeLike(post, userAddress){
        const response = await this.postLike.methods.removeLike(post).send({from: userAddress})
        return response;
    }

    async getLikeNotifications(){
        // this.postLike.events.allEvents({
        //     fromBlock: "0x0"
        // }, function(error, events){console.log(events);})
    }

}

export default PostController;


// const web3 = new Web3("wss://polygon-mumbai.g.alchemy.com/v2/Zl5jVUPWcKiBeiyiRQntX36WvNwBKDnK");