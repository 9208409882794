// import constants from "../../constants.json";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import EthereumContext from "../ethereum/ethereum";
import UserController from "./UserController";
import EthereumNetworkContext from "../ethereum/network";
import LocalStorageContext from "../localstorage/localstorage";

const userController= new UserController();

const UserContext = createContext({
  user: "",
  data: {
    username: null,
  },
  login: () => {},
  logout: () => {},
});

export function UserContextProvider(props) {
  const ethereumCtx = useContext(EthereumContext);
  const ethereumNetworkCtx = useContext(EthereumNetworkContext);
  const localStorageCtx = useContext(LocalStorageContext);
  const [userCurrent, setUser] = useState("");
  const [userUsername, setUsername] = useState({ens: "", username: ""});

  // async function login() {
  //   if (ethereumCtx) {
  //     ethereumNetworkCtx.makeCall(async()=> {
  //       const accounts = await ethereumCtx.request({ method: 'eth_requestAccounts' });
  //       loadAccount(accounts);
  //       loadAccountUsername(accounts);
  //     });
  //   } else {
  //     // alert("Install metamask");
  //   }
  // }

  useEffect(() =>{

    if(ethereumCtx  && localStorageCtx.loadLocalStorageUser()){
      login();
    }
  
    return () => {};
  }, [ethereumCtx, ethereumNetworkCtx ,localStorageCtx]);
  
  
  const loadAccount = useCallback(accounts => {
    setUser((prevuser) => accounts[0]);
    localStorageCtx.writeLocalStorageUser(accounts[0]);
  }, [])
  
  const loadAccountUsername = useCallback(async accounts => {
    const  username = await userController.getUser(accounts[0])
    localStorageCtx.localstorage.setItem('userDetails', JSON.stringify(username));
    setUsername(prevUserUsername => username);
  }, [])

  useEffect(() => {
    if(userCurrent) {
      userController.getUser(userCurrent).then((data) => {
        localStorageCtx.localstorage.setItem('userDetails', JSON.stringify(data));
        setUsername(data)
      })
    } else {
      setUsername("")
    }
   }, [ethereumCtx, userCurrent]);

  function logout() {
    setUser((prevUser)=> "");
    setUsername(prevUserUsername => "");
  }

  function login(){
   async function loadData() {
      if (ethereumCtx) {
        ethereumNetworkCtx.makeCall().then(()=> {
          ethereumCtx.request({ method: 'eth_requestAccounts' }).then(accounts=> {
            loadAccount(accounts);
            loadAccountUsername(accounts);
          })
        });
      } else {
        // alert("Install metamask");
      }
    };

    loadData();
  }
  // , [ethereumCtx, ethereumNetworkCtx, loadAccount, loadAccountUsername]);

  const context = {
    user: userCurrent,
    data: {
      username: userUsername
    },
    login: login,
    logout: logout,
  };

  useEffect(() => {
    if (ethereumCtx) {
      ethereumCtx.on('accountsChanged', loadAccount);
      // if (userCurrent == null  && window.localStorage.getItem('user')) {
      //   login();
      // }
      return () => ethereumCtx.removeListener('accountsChanged', loadAccount)
    }
  }, [ethereumCtx, loadAccount, userCurrent, login]);


  return (
    <UserContext.Provider value={context}>
      {props.children}
    </UserContext.Provider>
  );  
}

export default UserContext;

// function addFavoriteHandler() {}
// function removeFavoriteHandler() {}
// function itemIsFavoriteHandler() {}