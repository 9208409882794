// // import {useTranslation} from "react-i18next";
import React, { useContext, useEffect, useState } from 'react';
import PostController from "../../store/posts/PostController";
import HomePublish from "./HomePublish";
import HomePostList from "./HomePostList";
import DesktopLayout from "../../components/layout/DesktopLayout";
import EthereumContext from "../../store/ethereum/ethereum";
import EthereumNetworkContext from "../../store/ethereum/network";
import HomeWithOutMetamask from "./HomeWithOutMetamask";

function Home () {
    // const { t } = useTranslation();
    const [posts, setposts] = useState([])
    const ethereumCtx = useContext(EthereumContext)
    const ethereumNetworkCtx = useContext(EthereumNetworkContext);

    useEffect(() => {
        async function fetchData() {
            // if (ethereumCtx) {
                // await ethereumNetworkCtx.makeCall(async ()=>{
                    setposts(await new PostController().getPostsHome())
                // });
            // }
        }
        fetchData();
    }, [
        ethereumCtx, ethereumNetworkCtx
    ])

    // if (ethereumCtx) {
        return (
            <DesktopLayout>
                <HomePublish className="hidden md:block"/>
                <HomePostList posts={posts} />
                <div className="h-16"></div>
            </DesktopLayout>
        );
    // } else {
    //     return (
    //         <><HomeWithOutMetamask /></>
    //     );
    // }
}

export default Home;