import { createContext, useState } from "react";


const MenuMobileContext = createContext({
  isOpen: false,
  openMenu: ()=>{},
  closeMenu: ()=>{}
});

export function MenuMobileContextProvider(props) {
  const [open, setOpen] = useState(false);


  const context = {
    isOpen: open,
    openMenu: openMenu,
    closeMenu: closeMenu,
  };

  function openMenu() {
    setOpen(true);
  }

  function closeMenu() {
    setOpen(false);
  }


  return (
    <MenuMobileContext.Provider value={context}>
      {props.children}
    </MenuMobileContext.Provider>
  );  
}

export default MenuMobileContext;