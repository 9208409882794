import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PostHeader from "../../components/ui/Post/PostHeader";
import PostFooter from "../../components/ui/Post/PostFooter";
import UserContext from "../../store/user/user-context";
import UserController from "../../store/user/UserController";
import PostImage from "../../components/ui/Post/PostImage";
import PostActions from "../../components/ui/Post/PostActions";
import PostController from "../../store/posts/PostController";

const Post = (props) => {
    const location = useLocation();
    const [allLike, setAllLike] = useState([]);
    const [like, setLike] = useState([]);
    const { post, address } = location.state ?? props.post

    const [user, setUser] = useState(null)
    const userCtx = useContext(UserContext);

    useEffect(() => {
        async function fetchData() {
            if (post.owner != undefined) {
                if ( post.owner.toLowerCase() == userCtx.user ) {
                    setUser(userCtx.data.username);
                } else {
                    setUser(await new UserController().getUser(post.owner));
                }
                if (userCtx.user != "") {
                    // setLike(await new PostController().getUserPostLikePost(address, userCtx.user));
                    const data = await new PostController().getPostLikeRecived(address);
                    setAllLike(data);
                    setLike(data.filter(address => address.toLowerCase() == userCtx.user).length == 1);
                }
            }
          }
          if (post.owner != undefined) {
              fetchData();
          }
        return () => {}
    }, [post, userCtx.user, like])

    async function action() {
        if(like){
            const response =  await new PostController().removeLike(address, userCtx.user);
            setLike(false);
        } else {
            const response =  await new PostController().addLike(address, userCtx.user);
            setLike(true);
        }
    };
    
    if (post != null && user) {
        return (
        <>
            <div className="flex flex-col md:flex-col md:container md:m-auto">
                <PostHeader post={post} user={user} />
                <PostImage post={post} />
                <PostActions like={like} action={action} likes={allLike} />
                <PostFooter post={post} user={user}/>
            </div>
            <div className="h-16"></div>
        </>);
    } else {
        return (<></>);
    }
}

export default Post;