import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ProfilePictureController from "../../../store/imagePicture/ProfilePictureController";

const PostHeader = ({post, user}) => {
    console.log(user);
    const [profilePicture, setProfilePicture] = useState("");

    useEffect(() => {
        async function loadData(){
            setProfilePicture(await new ProfilePictureController().getProfilePicture(post.owner))
        }
        loadData();
        return () => {};
    }, [post]);
    
    return (
        <Link to={"/"+post.owner}>
            <div className="mx-4 my-2 nunito flex flex-row items-center gap-2">
                <div className="rounded-full bg-slate-700 h-10 w-10">
                    <img className="rounded-full object-cover h-10 w-10 bg-white" 
                        src={profilePicture!= ""? "https://socialsmartchain.digitalnext.business/ipfs/" + profilePicture : "/img/no-photo.jpeg" }
                    />
                </div>
                {user.ens.name == null ? (user.username!=="" ? user.username : post.owner.substr(0, 5) +"..."+ post.owner.substr(post.owner.length-5, 5)) : user.username + " | " + user.ens.name }
            </div>
        </Link>
    );
}

export default PostHeader;