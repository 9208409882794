import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PostFooter = ({post, user, time}) => {

    function printTime(timestamp){
        const dateObject = new Date(timestamp * 1000)
        const humanDateFormat = dateObject.toLocaleString() //2019-12-9 10:30:15
        return humanDateFormat;
    }

    return (
        <div className="mx-4">
            {/* <Link to={"/"+post.owner}>
                <p className="font-bold">{post.owner}</p>
            </Link> */}
            <p className="my-2">
                <Link to={"/"+post.owner}><span className="font-bold">
                    {user.ens.name == null ? user.username : user.username + " | " + user.ens.name }
                    {/* {user != null ? (user.ens ? user.username+" | "+user.ens.name : user.username) : post.owner.substring(0, 15)+"..."} */}
                    </span>
                </Link> {post.description}</p>
            <p className="text-gray-600 font-bold text-sm">{printTime(post.timestamp)}</p>
        </div>
    );
}

export default PostFooter;