import ENS from '@ensdomains/ensjs'
const Web3 = require("web3");
const jsonInterface = require("../../abi/AccountFactory.sol/AccountFactory.json");
// const constants = require("../../constants.json");
// var ENS = require('ethereum-ens');


class UserController {
    web3Contract;
    contract;

    constructor() {
        let web3 = null;
        if (window.ethereum) {
            web3 = new Web3(window.ethereum);
        } else {
            web3 = new Web3("https://polygon-mumbai.g.alchemy.com/v2/Zl5jVUPWcKiBeiyiRQntX36WvNwBKDnK");
            
        }
        this.web3Contract = web3.eth.Contract;
        this.contract = new this.web3Contract(jsonInterface["abi"], jsonInterface["networks"][80001]["address"]);
    }


    async getUser(address){
        var Web3 = require('web3');
        var provider= new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161");
        var ens = new ENS({provider,  ensAddress: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e' });
        const ensResolved  = await ens.getName(address);
        ensResolved.name = ensResolved.name ?? null;
        const dappUsername = await this.contract.methods.nameFromAddress(address).call();

        return {
            ens: ensResolved,
            username : dappUsername
        };
    }

    async editUsername(username, userCtx){
        // editUser
        var amount = await this.contract.methods.editUser(username).estimateGas({from: userCtx.user})

        var data = await this.contract.methods.editUser(username).send({from: userCtx.user, gas: amount})
            .on('transactionHash', function(hash){
                // console.log(hash);
                // return hash;
            })
            .on('confirmation', function(confirmationNumber, receipt){
                // console.log(confirmationNumber);
                // console.log(receipt);
            })
            .on('receipt', function(receipt){
                // console.log({success: receipt});
            }).on('error', function(error, receipt) {
                // console.log(error);
            });
        return {success: data};
    }

}

export default UserController;