import { useRef } from "react";
import imageCompression from 'browser-image-compression';

const FileUploader = ({onFileSelectError, onFileSelectSuccess, className}) => {
    const fileInput = useRef(null);

    const handleFileInput = async (e) => {
        const file = e.target.files[0];

        const options = {
            maxSizeMB: 1,
        //   maxWidthOrHeight: 1920,
            useWebWorker: true
        }

        const compressedFile = await imageCompression(file, options);


        if (compressedFile.size > (1048576))
          onFileSelectError({ error: "File size cannot exceed more than 1MB" });
        else onFileSelectSuccess(compressedFile);
    };



    return (
        <div className="file-uploader">
            <input type="file" id="file" className={className} onChange={handleFileInput}></input>
        </div>
    );
}

export default FileUploader;
