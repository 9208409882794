import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React,{ useContext } from "react";
import UserContext from "../../store/user/user-context";
import MenuIcon from "../ui/mobile/menu/menu.js";
import MenuMobileContext from "../../store/menu-mobile/menu-mobile-context";
import Search from "../ui/Search/Search";
import Notification from "../ui/Notification/Notification";

function MainNavigation(){
    const { t } = useTranslation();
    const userCtx = useContext(UserContext);
    const menuCtx = useContext(MenuMobileContext);

    let AccountButton;

    if(userCtx.user !== ""){
        AccountButton = <Link to={t('routes.profile')}><button>{userCtx.user}</button></Link>;
    } else {
        AccountButton = <button onClick={userCtx.login}>Login</button>
    }

    return (
        <header className="flex flex-row justify-between items-center px-[10px] md:px-9 sticky top-0 bg-white md:shadow-md border-b-2 z-50">
            <ul  className="flex gap-4 h-14  items-center basis-1/4">
                <li className="font-bold text-xl">
                    {/* <Link to={t('routes.home')} >{t('sitename')}</Link> */}
                    <Link to={t('routes.home')} >< img src="/logo.png" className="w-28" /></Link>
                </li>
            </ul>
            <ul className="hidden md:block md:w-[50vw]">
                {/* <li>
                    <Search />
                </li> */}
            </ul>
            <ul className="hidden md:flex md:flex-row md:justify-end md:items-center md:gap-4 md:basis-1/5 md:text-sm relative">
                {userCtx.user ?  <li><Notification /></li> : "" }
                <li>{AccountButton}</li>
            </ul>
            <ul className="md:hidden flex flex-row gap-4">
                {userCtx.user ?  <li><Notification /></li> : "" }
                <li><MenuIcon onClick={()=> menuCtx.openMenu()} /></li>
            </ul>
        </header>
    );
}

export default  MainNavigation;