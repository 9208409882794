import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PostActions from "../../components/ui/Post/PostActions";
import PostHeader from "../../components/ui/Post/PostHeader";
import ProfilePictureController from "../../store/imagePicture/ProfilePictureController";
import PostController from "../../store/posts/PostController";
import UserContext from "../../store/user/user-context";

const PostPreviewWithOutPhoto = ({user, post, time, address}) => {
    const userCtx = useContext(UserContext);
    const [like, setLike] = useState(false);
    const [allLike, setAllLike] = useState(false);

    async function action() {
        if(like){
            const response =  await new PostController().removeLike(address, userCtx.user);
            setLike(false);
        } else {
            const response =  await new PostController().addLike(address, userCtx.user);
            setLike(true);
        }
    };

    useEffect(async() => {
        const data = await new PostController().getPostLikeRecived(address);
        setAllLike(data);
        setLike(data.filter(address => address.toLowerCase() == userCtx.user).length == 1);
        return () => {};
    }, [userCtx.user, like]);

    if(user){
        return (
            <>
                <PostHeader post={post} user={user}/>
                <p className="my-2 mx-4">{post.description}</p>
                <PostActions like={like} action={action} likes={allLike} />
                <p className="text-gray-600 font-bold text-sm mx-4">{time}</p>
            </>
        );
    } else {
        return (<></>);
    }
}

export default PostPreviewWithOutPhoto;