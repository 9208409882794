import { createContext, useEffect, useState } from "react";
const EthereumContext = createContext({
    ethereum: null
});

export function EthereumContextProvider(props) {
    const [ethereum, setEthereum] = useState(null);
    useEffect(() => {
        if (typeof window.ethereum !== 'undefined') {
            setEthereum(window.ethereum);
        }
        else {
            // alert("You need install Metamask to use this App!");
            setEthereum(undefined);
        }
    }, [ethereum])

    const context = ethereum;
    return (
        <EthereumContext.Provider value={context}>
          {props.children}
        </EthereumContext.Provider>
    );
};

export default EthereumContext;