import { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PostActions from "../../components/ui/Post/PostActions";
import PostFooter from "../../components/ui/Post/PostFooter";
import PostHeader from "../../components/ui/Post/PostHeader";
import PostImage from "../../components/ui/Post/PostImage";
import ProfilePictureController from "../../store/imagePicture/ProfilePictureController";
import PostController from "../../store/posts/PostController";
import UserContext from "../../store/user/user-context";

const PostPreviewWithPhoto = ({user, post, time, address}) => {
    const [profilePicture, setProfilePicture] = useState("");
    const userCtx = useContext(UserContext);
    const [like, setLike] = useState(false);
    const [allLike, setAllLike] = useState(false);

    useEffect(() => {
        async function loadData(){
            setProfilePicture(await new ProfilePictureController().getProfilePicture(post.owner))
        }
        loadData();
        return () => {};
    }, [post, userCtx.user]);

    async function action() {
        if(like){
            const response =  await new PostController().removeLike(address, userCtx.user);
            setLike(false);
        } else {
            const response =  await new PostController().addLike(address, userCtx.user);
            setLike(true);
        }
    };

    useEffect(async() => {
        const data = await new PostController().getPostLikeRecived(address);
        setAllLike(data);
        setLike(data.filter(address => address.toLowerCase() == userCtx.user).length == 1);
        return () => {};
    }, [userCtx.user, like]);
    
    

    // && userCtx.user != ""
    if(user){
        return (
            <>
                <PostHeader post={post} user={user} />
                <PostImage post={post} />
                <PostActions like={like} action={action} likes={allLike}/>
                <PostFooter post={post} user={user}/>
            </>
        );
    } else {
        return (<></>);
    }
}

export default PostPreviewWithPhoto;