import { useContext, useEffect, useState } from "react";
import UserContext from "../../store/user/user-context";
import UserController from "../../store/user/UserController";
import imageCompression from 'browser-image-compression';
import PostController from "../../store/posts/PostController";
import ProfilePictureController from "../../store/imagePicture/ProfilePictureController";
import OpenSeaController from "../../store/checkPermission/OpenSeaController";
import { useTranslation } from "react-i18next";

const EditProfile = () => {
    const {t} = useTranslation()
    const userCtx = useContext(UserContext);
    const [user, setUser] = useState(null);
    const [username, setUsername] = useState(null);
    const [profilePicture, setProfilePicture] = useState("");
    const [saving, setSaving] = useState(false);
    const [savingPhotoUpdate, setSavingPhotoUpdate] = useState(false);
    const [error, setError] = useState("");
    const [errorPhotoUpdate, setErrorPhotoUpdate] = useState("");
    const [success, setSuccess] = useState("");
    const [successPhotoUpdate, setSuccessPhotoUpdate] = useState("");
    const nftChecker = new OpenSeaController();

    useEffect(() => {
        async function loadData(){
            setProfilePicture(await new ProfilePictureController().getProfilePicture(userCtx.user))
        }

        loadData();
        return () => {};
    }, [userCtx]);

    

    useEffect(() => {
        async function loadData() {
            setUser({data: userCtx.data, address: userCtx.user});
            setUsername(userCtx.data.username.username);
        }
        if (userCtx) {
            loadData();   
        }
      return () => {};
    }, [userCtx]);

    const uploadFile = async (file) => {
        setSavingPhotoUpdate(true);
        setErrorPhotoUpdate("");
        setSuccessPhotoUpdate("");
        if (file.size < (1048576)){
            const response = await new PostController().uploadPhoto({file: file});
            const upload = await  new ProfilePictureController().setProfilePicture(response.data.Hash, userCtx.user);
            setProfilePicture(response.data.Hash);
            setSavingPhotoUpdate(false);
            setSuccessPhotoUpdate("Success");
            setTimeout(() => {
                setSuccessPhotoUpdate("");
            }, 3000);
        }
    };

    async function handleImageUpload(event) {
        if (await nftChecker.getAccess(userCtx.user)) {  
        } else {
            setSaving(false);
            setErrorPhotoUpdate("Error you don't have Nfts to use this d-App!");
            return;
        }

        const imageFile = event.target.files[0];
      
        const options = {
            maxSizeMB: 1,
        //   maxWidthOrHeight: 1920,
            useWebWorker: true
        }

        try {
            const compressedFile = await imageCompression(imageFile, options);
          await uploadFile(compressedFile); // write your own logic
        } catch (error) {
            console.log(error);
        }
      
    }

    async function handleSubmit(){
        setSaving(true);
        setError("");
        setSuccess("");
        if (username !== userCtx.data.username.username) {
            if (await nftChecker.getAccess(userCtx.user)) {  
            } else {
                setSaving(false);
                setError("Error you don't have Nfts to use this d-App!");
                return;
            }
            new UserController().editUsername(username, userCtx).then((data)=> {
                if(data.success){
                    setSaving(false);
                    setSuccess("Success");
                    userCtx.login()
                    setTimeout(() => {
                        setSuccess("");
                    }, 3000);
                } else {
                    setError("Error during saving");
                    setSaving(false);
                }
            })
        } else {
            setError("Username already exist!");
            setSaving(false);
        }
    }
    
    if(user){
        const inputStyle="w-full placeholder:italic placeholder:text-gray-400 block bg-white border border-gray-300 rounded-md py-2 px-3 shadow-sm sm:text-sm focus:outline-none focus:shadow-md";
        return (
            <>
                <div className="container mx-auto">
                    <div className="m-2 md:flex md:flex-col lg:w-1/3 md:border md:p-4">
                        <section className="flex flex-row justify-center relative">
                            <div className="h-28 w-28 flex flex-col items-end">
                                {/* bg-violet-800 */}
                                <div className="h-28 w-28 bg-white rounded-full">
                                    <img className="rounded-full object-cover h-28 w-28" 
                                        src={profilePicture!== ""? "https://socialsmartchain.digitalnext.business/ipfs/" + profilePicture : "/img/no-photo.jpeg" }
                                        alt="profile"
                                    />
                                </div>
                                <div className="absolute bottom-0 bg-white rounded-full p-2 border" onClick={()=>document.querySelector('#profilePicture').click()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="absolute right-0 hidden">
                                <input type="file" accept="image/*" name="profilePicture" id="profilePicture" onChange={handleImageUpload}/><br/>
                            </div>
                        </section>
                        <section className="my-2">
                            {savingPhotoUpdate ? 
                                <div className="flex items-center justify-center">
                                    <button type="button" className=" inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed" disabled>
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg> Saving...
                                    </button>
                                </div>
                            : ""}
                            {errorPhotoUpdate.length > 0 ? <p className="bg-red-200 text-red-900 p-2 px-3 rounded-lg mt-2">{errorPhotoUpdate}</p> : ""}
                            {successPhotoUpdate.length > 0 ? <p className="bg-green-200 text-green-900 p-2 px-3 rounded-lg mt-2">{successPhotoUpdate}</p> : ""}
                        </section>
                        <section className="my-4">
                            <div>
                                <p className="text-center text-[12px]">
                                    <span className="bg-violet-300 rounded-full p-2 text-white cursor-pointer" 
                                        onClick={()=>navigator.clipboard.writeText(user.address)}>
                                            {user.address}
                                    </span>
                                </p>
                            </div>
                        </section>
                        <section className="h-2"></section>
                        <form method="POST" onSubmit={event=>event.preventDefault()} className="px-2 md:px-0">
                            <div className="relative">
                                <label htmlFor="ens" className="mx-2">ENS:</label>
                                <input value={user.data.username.ens.name} disabled className={inputStyle+" text-gray-600 cursor-not-allowed"} placeholder="Ens domain" type="text" name="ens" id="ens"/>
                                <div className="h-4"></div>
                                <label htmlFor="username" className="mx-2">Username: </label>
                                <input onChange={event => setUsername(event.target.value)} value={username} className={inputStyle} placeholder="Choose your username" type="text" name="username" id="username"/>
                                <button onClick={handleSubmit} className="text-sm mr-4 py-2 px-4 rounded-full border-0 font-semibold bg-violet-50  text-violet-700 hover:bg-violet-100 absolute right-0 bottom-0">{t("msg.send")}</button>
                                <div className="h-14"></div>
                            </div>
                        </form>
                        {saving ? 
                            <div className="flex items-center justify-center">
                                <button type="button" className=" inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed" disabled>
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg> Saving...
                                </button>
                            </div>
                        : ""}
                        {error.length > 0 ? <p className="bg-red-200 text-red-900 p-2 px-3 rounded-lg mt-2">{error}</p> : ""}
                        {success.length > 0 ? <p className="bg-green-200 text-green-900 p-2 px-3 rounded-lg mt-2">{success}</p> : ""}
                    </div>
                </div>
            </>
        );
    } else {
        return <>Not Logged</>;
    }
}

export default EditProfile;