import TimeStamp from "./Timestamp";
import { ImgProfileFromAddress, Username } from "../Post/ListLikes";
import Post from "../../../pages/Post";
import { useEffect, useState } from "react";
import PostController from "../../../store/posts/PostController";

const PostImageFromAddress = ({address}) => {
    const [post, setPost] = useState({description: "", url: ""});

    useEffect(() => {
        async function loadData() {
            setPost(await new PostController().getPostFromAddress(address));
        }

        loadData();
        return () => {};
    }, []);
    
console.log(post.url);
    return (
        <div className={post.url !== "" ? "" : "border p-4 h-36 flex flex-col justify-center"}>
            {post.url !== "" ? <img src={"https://socialsmartchain.digitalnext.business/ipfs/" + post.url} /> : ""}
            {post.description}
        </div>
    );
}


const ListNotification = ({post, block, user}) => {
    return (
        <div className="flex flex-row gap-4 items-center justify-between px-4 my-4 w-full">
            <div className="flex flex-col overflow-hidden">
                <div className="flex items-center gap-4 p-4">
                    <ImgProfileFromAddress address={user} />
                    <div className="flex flex-col">
                        {/* dark:text-slate-200 */}
                        <strong className="text-slate-900 text-sm font-medium "><Username address={user}/></strong>
                        {/* dark:text-slate-400 */}
                        <span className="text-slate-500 text-sm font-medium ">{user}</span>
                    </div>
                </div>
                <div className="px-4">
                    <TimeStamp block={block} /> 
                </div>
            </div>
        <div>
            <div className="w-36 my-4 overflow-clip"><PostImageFromAddress address={post} /></div>
        </div>
    </div>
    );
}

export default ListNotification;