import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationIT from './it-IT';
import translationEN from './en-EN';

const resources = {
    en: {
        translation: {...translationEN }
    },
    it: {
        translation: {...translationIT }
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        debug: true,
        fallbackLng: 'en',
        preload: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;