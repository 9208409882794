import PostHome from "./post";

const HomePostList = (props) => {
    const posts = props.posts ?? [];

    return (
        [...posts].reverse().map((item, index) => 
            <div key={index} className="mb-8 md:mb-4">
                <PostHome post={item} className="md:border md:mb-4" />
            </div>
        )
    );
}
export default HomePostList;