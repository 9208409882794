import React, { useCallback, useContext, useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import PostPreview from "../../components/ui/Profile/PostPreviews";
import ProfilePicture from "../../components/ui/Profile/ProfilePicture";
import EthereumContext from "../../store/ethereum/ethereum";
import EthereumNetworkContext from "../../store/ethereum/network";
import ProfilePictureController from "../../store/imagePicture/ProfilePictureController";
import PostController from "../../store/posts/PostController";
import UserContext from "../../store/user/user-context";
import PostContext from "../../store/posts/post-context";
import UserController from "../../store/user/UserController";

const Profile = (props)=>{
    const { t } = useTranslation();
    const userCtx = useContext(UserContext); 
    const ethereumNetworkCtx = useContext(EthereumNetworkContext); 
    const ethereumCtx = useContext(EthereumContext); 
    const [posts, setPosts] = useState([]);
    const [countPost, setCountPosts] = useState(0);
    const [username, setUsername] = useState("");
    const [profilePicture, setProfilePicture] = useState("");
    const [address, setAddress] = useState(null);
    const postCtx = useContext(PostContext)

    const postController = useEffect(() => {
        new PostController()
    }, []);

    useEffect(() => {
        async function loadData(){
            if(props.match.params.address !== undefined){
                setProfilePicture(await new ProfilePictureController().getMyProfilePicture(props.match.params.address))
            }else {
                setProfilePicture(await new ProfilePictureController().getMyProfilePicture(userCtx.user))
            }
        }
        loadData();
        return () => {};
    }, [userCtx, props.match.params]);
    
    

    const login = useCallback(async ()=>{
        if(props.match.path === t("routes.profile") && userCtx.login != undefined && userCtx.user === ""){
            await userCtx.login();
        }
    }, [props.match.path, t, userCtx]);
    
    useEffect( ()=>{
        async function loadData() {
            await login();

            if(props.match.params.address !== null && props.match.params.address !== undefined ){
                setAddress("0x"+props.match.params.address);
            }  else if( props.match.params.address === undefined) {
                if (userCtx.user !== null && userCtx.user.trim(" ") !== "") {
                    setAddress(userCtx.user);
                }
            }
        }
        loadData();
        
    }, [address, userCtx, ethereumNetworkCtx, login, props]);

    useEffect(() => {
        async function loadData() {
            if(ethereumCtx && address != null) {
                if(props.match.path === t("routes.profile") && userCtx.user !== ""){
                    if(userCtx.data.ens === null){
                        setUsername(userCtx.data.username === "" ?  {username: 'unamed'} : userCtx.data.username);
                    } else {
                        setUsername(userCtx.data.username);
                    }
                } else {
                    ethereumNetworkCtx.makeCall(async ()=> {
                        let user = await new UserController().getUser(address);
                        if (user) {
                            setUsername(user);
                        }
                    })
                }
                await ethereumNetworkCtx.makeCall(async () => {
                    console.log(props.match.params.address);
                    if(props.match.params.address !== undefined){
                        const postController = new PostController();
                        postController.countPosts(address).then(setCountPosts);
                        postController.getPostsFromOwner(address).then(setPosts);
                    } else {
                        setCountPosts(postCtx.posts.length);
                        setPosts(postCtx.posts)
                    }
                })
            }
        }
        loadData();
    }, [address, t, props.match.path, postController, ethereumCtx, userCtx, ethereumNetworkCtx, postCtx]);
    

    function grid() {
        return [...posts].reverse().map(item => {
            return (
                <PostPreview 
                    key={item} 
                    className="object-cover w-[33vw] h-[33vw] xl:w-[512px] xl:h-[512px]" 
                    post={item} 
                />
            );
        })
        // return [...posts].reverse().map(item => {
        //     return (
        //         <PostPreview 
        //             key={item} 
        //             className="object-cover w-[33vw] h-[33vw] xl:w-[512px] xl:h-[512px]" 
        //             post={item} 
        //         />
        //     );
        // })
    }

    if (posts && username) {
        return (
            <>
                <section className="container mx-auto  gap-4 py-4 text-center">
                    <ProfilePicture url={profilePicture!= ""? "https://socialsmartchain.digitalnext.business/ipfs/" + profilePicture : "/img/no-photo.jpeg" } class="rounded-full object-cover h-28 w-28 m-auto"/>
                    <div className="flex flex-col mt-2">
                        <h1 className="text-2xl font-bold flex flex-col md:flex-row justify-center items-center gap-2">
                            {username.username !== "" ? username.username : "Unnamed"}
                            <span className="hidden md:block">{username.ens !== null && username.ens.name !== null ?" | " : ""}</span>
                            <span className="flex flex-row gap-4 items-center">{username.ens !== null && username.ens.name !== null ? username.ens.name : "" } 
                            {username.ens && username.ens.name ? <img src="/img/verified.png" alt="verified" className="w-6"/> : ""}</span>
                        </h1>
                        <h2 className="text-base">{countPost} Posts</h2>
                    </div>
                </section>
                <section className="container m-auto">
                    <div className="grid grid-cols-3 gap-1">
                        { grid() }
                    </div>
                </section>
                <section className="h-[70px]"></section>
            </>
        );
    } else {
        return (
            <>
                <section className="container mx-auto gap-4 py-4 text-center animate-pulse">
                    <ProfilePicture url={"https://via.placeholder.com/300" } class="w-28 m-auto"/>
                    <div className="flex flex-col mt-2 items-center gap-4">
                        <div className="animate-pulse bg-slate-700 flex w-52 h-2 rounded"></div>
                        <div className="h-2 w-12 bg-slate-700 rounded"></div>
                    </div>
                </section>
                <section className="container m-auto animate-pulse grid grid-cols-3 gap-1">
                    {[...Array(12).keys()].map((item, key) => <div key={key} className="w-[33vw] h-[33vw] xl:w-[512px] xl:h-[512px] bg-slate-700"></div>)}
                </section>
            </>
        )
    }
}


export default  Profile;