import HomePublish from "../Home/HomePublish";

const AddPost = () => {
    return (
        <>
            <HomePublish className="my-2" />
        </>
    )
}

export default AddPost;