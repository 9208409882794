import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PostController from "../../../store/posts/PostController";

const PostPreview = (props) => {
    const [post, setPost] = useState(null)
    const postAddress = props.post;


    useEffect(() => {
        async function fetchData() {
            const postContent = await new PostController().getPostFromAddress(postAddress);
            if(postContent.url.length == 46){
                const response = await axios.get("https://socialsmartchain.digitalnext.business/ipfs/" + postContent.url)
                return (response.data != null && response.data !== "[object Object]") ? setPost(prevPost => postContent) : setPost(prevPost => null);
            }
          }
          fetchData();
        return () => {/* cleanup*/}
}, [postAddress]);

    if (post != null) {
        return (
            <Link to={{ pathname: "/post/" +  postAddress, state: {post: post, address: postAddress}}}>
                <img src={"https://socialsmartchain.digitalnext.business/ipfs/" + post.url} className={props.className} alt={"Post "+postAddress} />
            </Link>
        )
    } else {
        return (<></>);
    }
}

export default PostPreview;