import axios from "axios";

class NFTSController{

    newNfts() {
        const url = "https://api.opensea.io/api/v1/assets";
        return axios.get(url).then(
            response => response.data
        )
    }
}


export default NFTSController;