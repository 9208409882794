const Web3 = require("web3");
const constants = require("../../constants.json");

class WalletController {
    web3Eth;

    constructor() {
        if (window.ethereum) {
            // const web3 = new Web3("wss://polygon-mumbai.g.alchemy.com/v2/Zl5jVUPWcKiBeiyiRQntX36WvNwBKDnK");
            const web3 = new Web3(constants.urlRpc);
            // const web3 = new Web3(window.web3.currentProvider);
            this.web3Eth = web3.eth;
        }
    }

    async getBalance(address){
        return await this.web3Eth.getBalance(address);
    }
}

export default WalletController;