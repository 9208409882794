import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DesktopLayout from "../../components/layout/DesktopLayout";
import NFTSController from "../../store/nfts/nfts-controller";

const Shop = () => {
    const nftsController = new NFTSController();
    const [nfts, setNfts] = useState({assets: []})

    useEffect(()=> {
        async function loadData() {
            setNfts(await nftsController.newNfts());
        }
        loadData()
    }, []);

    return (
            <>
                <DesktopLayout>
                    <h1 className="text-3xl font-bold text-center text-opacity-50">Nfts Marketplace</h1>
                    {nfts.assets.map((item, key) => 
                        <div key={key} className="py-3 my-3">
                            {/* {JSON.stringify(item)} */}
                            <Link to={{ pathname: "/nft/" +  item.id, state: {nft: item}}}>
                                <img src={item.image_url} width="100%"/>
                            </Link>
                            <p className="mx-[10px] text-center">{item.name}</p>
                        </div>
                    )}
                    <div className="h-[10vw]"></div>
                </DesktopLayout>
        </>
    );
}

export default Shop;