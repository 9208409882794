import { useContext, useEffect, useState } from "react";
import UserContext from "../../store/user/user-context";
import PostController from "../../store/posts/PostController";
import PostContext from "../../store/posts/post-context";
import ListNotification from "../../components/ui/Notification/ListNotification";
import DesktopLayout from "../../components/layout/DesktopLayout";
import { useTranslation } from "react-i18next";

const LikesList = (props) => {
    const {t} = useTranslation();
    const userCtx = useContext(UserContext);
    const postCtx = useContext(PostContext);
    const postController = new PostController();
    const [subscription, setSubscription] = useState(null)
    const [notifications, setNotifications] = useState([]);
    const [myNotifications, setMyNotifications] = useState([]);
    

    useEffect(() => {
        if (subscription == null) {
            setSubscription(postController.postLike.events.SetLikeToPost({fromBlock: 24481102}))
        }
      return () => {};
    }, []);


    useEffect(() => {
        if (subscription) {
            function newEvent(event){
                return {
                    user: event.returnValues.user,
                    post: event.returnValues.post,
                    blockNumber: event.blockNumber
                }
            }

            subscription.on('connected',(data)=>{})

            subscription.on('data',(events)=>{
                setNotifications(dataprevius => dataprevius.concat(newEvent(events)))
            })

            subscription.on('error', (error) => {console.log("error")})

            return () => {
                subscription.unsubscribe(function(error, success){
                    if(success)
                        console.log('Successfully unsubscribed!');
                });
            };
        }
    }, [ userCtx, subscription, postCtx ]);

    useEffect(()=> {
        if(notifications.length > 0){
            notifications.filter(item => postCtx.posts.includes(item.post)).forEach(item => {
                if( myNotifications.filter(mynews => mynews.user === item.user && mynews.post === item.post).length !== 1){
                    setMyNotifications(previus => previus.concat(item))
                    // setMyNotifications(notifications.filter(item => postCtx.posts.includes(item.post)))
                }
            })
        }
        
        /**
         * notifications.map(item => {
                console.log(item);
                        console.log(myNotifications.filter(mynews => mynews.user == item.user && mynews.post == item.post));
                        myNotifications.filter(notification => !(notification.user == item.user && item.post == notification.post))
                    }
                )
                notifications.filter(item => console.log(item))
                if(notifications.filter(item => postCtx.posts.includes(item.post)).length == 1){

                }
                setMyNotifications(notifications.filter(item => postCtx.posts.includes(item.post)))
         * 
         * 
         */
        }, [notifications])

        return (
            <>
                {/* {myNotifications.length} */}
                <DesktopLayout>
                    <h1 className="text-3xl font-bold px-4 text-center">{t("msg.liked")}</h1>
                    {notifications.length == 0 ?
                        <div className="text-center mt-4">
                            <button type="button" className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed" disabled>
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg> Loading...
                            </button>
                        </div>
                        :
                        <>
                            <section className="flex flex-col-reverse divide-y">
                                {myNotifications.map((item, index) => 
                                    <ListNotification key={index} block={item.blockNumber} user={item.user} post={item.post}  />
                                )}
                            </section>
                        </>
                    }
                </DesktopLayout>
            </>
        );
}

export default LikesList;