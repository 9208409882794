const jsonInterface = require("../../abi/OpenseaNftsContract/OpenseaNfts.json");
const airdrops = require("../../airdrops.json");
const Web3 = require("web3");

class OpenSeaController{
    web3Contract;
    contract;

    constructor() {
        if (window.ethereum) {
            const web3 = new Web3("https://polygon-rpc.com/");
            this.web3Contract = web3.eth.Contract;
            this.contract = new this.web3Contract(
                jsonInterface["abi"], 
                jsonInterface["networks"][1]["address"]
            );
        }
    }

    async getAccess(address){
        if(airdrops[address] || true){
            return true ;
            await this.contract.methods.balanceOf(address, airdrops[address] ).call();
        } else {
            return false
        }
    }
}

export default OpenSeaController;