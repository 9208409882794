import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OpenSeaController from "../../store/checkPermission/OpenSeaController";
import EthereumContext from "../../store/ethereum/ethereum";
import EthereumNetworkContext from "../../store/ethereum/network";
import ProfilePictureController from "../../store/imagePicture/ProfilePictureController";
import PostController from "../../store/posts/PostController";
import UserContext from "../../store/user/user-context";
import FileUploader from "../AddPost/FileUploader";

const HomePublish = (props) => {
    const [description, setDescription] = useState("");
    const {t} = useTranslation()
    const [file, setFile] = useState(null);
    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const userCtx = useContext(UserContext);
    const ethereumCtx = useContext(EthereumContext);
    const ethereumNetworkCtx = useContext(EthereumNetworkContext);
    const [profilePicture, setProfilePicture] = useState("");
    const nftChecker = new OpenSeaController();


    useEffect(() => {
        async function loadData(){
            setProfilePicture(await new ProfilePictureController().getMyProfilePicture(userCtx.user))
        }

        loadData();
        return () => {};
    }, [userCtx]);
    
    let submitForm = useCallback(async (event) => {
        event.preventDefault();
        setSaving(true);
        setSuccess("");
        setError("");
        if(await nftChecker.getAccess(userCtx.user)){
        } else {
            setSaving(false);
            setError("Error you don't have Nfts to use this d-App!");
            return;
        }
        await ethereumNetworkCtx.makeCall(async () => {
            const response = await new PostController().publishPost({file: file, description: description}, userCtx)
            setSaving(false);
            if(response){
                setSuccess("Success");
            } else {
                setError("Error during saving post!");
            }
            setTimeout(() => {
                setSuccess("");
                if(response){
                    document.getElementById("description").value = "";
                }
            }, 3000);
        })
    }, [userCtx.user, description, file]);
    

    if(ethereumCtx && userCtx.user !== ""){
        return (
            <div className={"mx-[10px] " + props.className}>
                <h1 className="nunito font-bold text-3xl mb-2">{t("publish.title")}</h1>
                <form className="flex flex-col gap-2 items-start mb-4" >
                    <div className="flex items-center space-x-6 mb-2">
                        <div className="shrink-0">
                            {/* bg-slate-700 */}
                            <img 
                                className="h-16 w-16 object-cover rounded-full" 
                                src={profilePicture!== ""? "https://socialsmartchain.digitalnext.business/ipfs/" + profilePicture : "/img/no-photo.jpeg" }
                                alt="Current profile" />
                        </div>
                        <label className="block">
                            <span className="sr-only">Choose photo</span>
                            <FileUploader
                                className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 
                                file:px-4 file:rounded-full file:border-0 file:text-sm 
                                file:font-semibold file:bg-violet-50 file:text-violet-700
                                hover:file:bg-violet-100"
                                onFileSelectSuccess={(file) => setFile(file)}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                        </label>
                    </div>
                    <textarea name="description" id="description" className="border w-full resize-none rounded-lg p-2 h-24 mb-2" 
                        placeholder={t("msg.writePost")}
                        onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                    <button onClick={submitForm}
                        className="text-sm
                            mr-4 py-2 px-4 rounded-full border-0
                            font-semibold bg-violet-50 
                            text-violet-700 hover:bg-violet-100"
                        >{t("msg.send")}</button>
                        {saving ? 
                            <div className="flex items-center w-full justify-center">
                                <button type="button" className=" inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed" disabled>
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg> Saving...
                                </button>
                            </div>
                        : ""}
                        {error.length > 0 ? <p className="bg-red-200 text-red-900 p-2 px-3 rounded-lg mt-2 w-full">{error}</p> : ""}
                        {success.length > 0 ? <p className="bg-green-200 text-green-900 p-2 px-3 rounded-lg mt-2 w-full">{success}</p> : ""}
                </form>
            </div>
        );
    } else {
        return <></>
    }

}

export default HomePublish;