const HomeWithOutMetamask = () => {
    const items = [
        {
            img: "https://images.ctfassets.net/9sy2a0egs6zh/4zJfzJbG3kTDSk5Wo4RJI1/1b363263141cf629b28155e2625b56c9/mm-logo.svg",
            description: <>1. Install <a className="text-red-400" target="_blank" rel="noreferrer" href="https://metamask.io/">Metamask</a></>,
            bgColor: "bg-orange-300",
            text: "You need install Metamask on your device. You can install: chrome plugin or Metamask app for iOS and Android."
        },
        {
            img: "https://faucet.polygon.technology/img/navbar-logo.a9775f2d.svg",
            description: <>2. Switch Network to Polygon Mumbai</>,
            bgColor: "bg-purple-300",
            text: "For use Social Smart Chain you need switch on Poligon Testnet Mumbai network. If you don't know how do this Sochain will help you in that. Metamask will ask you if you want add Polygon Testnet Chain. You need only accept!"
        },
        {
            img: "https://faucet.polygon.technology/img/navbar-logo.a9775f2d.svg",
            description: <>3. Go on <a className="text-red-400" href="https://faucet.polygon.technology/" rel="noreferrer" target="_blank">Faucet Mumbai</a></>,
            bgColor: "bg-violet-300",
            other: "Faucet",
            text: "Add founds using Polygon Faucet for pay gas fee. Is easy: go on Polygon Faucet, write your address and ask founds!",
        },
    ];

    return (
        <>
            <div>
                <div className="md:h-16"></div>
                <img src="sochain-stop-selling-your-data.png" className="m-auto w-[278px]" alt="Sochain Stop salling your data" />
                <div className="md:h-16"></div>
                {/* <h1 className="md:text-center text-8xl font-bold text-opacity-50 my-4 mx-4">Steps</h1>
                <div className="md:h-16"></div> */}
                <ul className="flex flex-col justify-between text-3xl place-items-center">
                    {
                        items.map( (item, index) => {
                            const className = index % 2 === 0? "md:flex-row bg-gray-100" : "md:flex-row-reverse bg-gray-200";
                            return (
                                <div key={index} className={index % 2 === 0? "bg-gray-100 w-full" : "bg-gray-200 w-full"}>
                                    <div className={"container md:m-auto px-4 md:px-0 flex flex-col justify-between items-center w-full py-4 " + className }>
                                        <li className="h-[550px] md:w-[550px] flex flex-col items-center justify-center text-center">
                                            <div className="z-10">
                                                <span className="flex justify-center items-center"><img src={item.img} width={250} alt={item.description} />{item.other}</span>
                                                <span>{item.description}</span>
                                            </div>
                                            <div className={"md:rounded-full h-[550px] md:w-[550px] opacity-50 absolute z-0 overflow-x-hidden "+ item.bgColor+""}></div>
                                        </li>
                                        <div className="md:w-[550px]">
                                            {/* pacifico */}
                                            <p className={"font-bold text-" + item.bgColor+"-300 "}>{ item.text }</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </ul>
                <div className="h-16"></div>
            </div>
        </>
    );
}

export default HomeWithOutMetamask;