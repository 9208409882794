import React, { Component }  from 'react';
import ProfilePicture from "../../components/ui/Profile/ProfilePicture";
import PageHeader from "../../components/ui/Titles/PageHeader";

const Categories = () => {
    function grid() {
        let columns = [];
        for (let index = 0; index < 9; index++) {
            columns.push(
                <div key={index}>
                    <div className="flex flex-col items-center grayscale hover:filter-none duration-300 ease-in-out">
                        <ProfilePicture url={"https://picsum.photos/id/" + 1+index + "/300/300"} />
                        <p className="pacifico text-5xl w-72 h-72 flex flex-col items-center justify-center absolute text-white">{index}</p>
                    </div>
                </div>
            );
        }
        return columns;
    }

    return (
        <div className="container mx-auto">
            <PageHeader className="text-center mb-4 pacifico">Categories</PageHeader>
            <div className="grid grid-cols-3 gap-3 justify-center items-center">
                {grid()}
            </div>
        </div>
    );
}

export default Categories;