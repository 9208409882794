import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ProfilePictureController from "../../../store/imagePicture/ProfilePictureController";
import UserController from "../../../store/user/UserController";
import CloseIcon from "../mobile/menu/close";

export function ImgProfileFromAddress({address}){
    const [data, setData] = useState(null);

    useEffect(() => {
        async function loadData(){
            setData(await new ProfilePictureController().getProfilePicture(address));
        }
        loadData()
        return () => {};
    }, []);

    if (data == null) {
        return <div className="animate-pulse rounded-full bg-slate-700 w-12 h-12"></div>;
    }else {
        return (
            <img className="w-12 h-12 rounded-full" 
                src={ 
                    data != ""? "https://socialsmartchain.digitalnext.business/ipfs/" + data : "/img/no-photo.jpeg"
                }
            />
        );
    }
}

export function Username({address}) {
    const [user, setUser] = useState(null);

    useEffect(() => {
        async function loadData(){
            setUser(await new UserController().getUser(address));
        }

        loadData();
        return () => {};
    }, []);

    if(user){
        return (
            <>
                { 
                    user.ens.name == null ?  
                    (user.username!=="" ? user.username : address.substr(0, 5) +"..."+ address.substr(address.length-5, 5)) 
                    : user.username + " | " + user.ens.name
                }
            </>
        );
    } else {
        return <div className="animate-pulse bg-slate-700 flex space-x-4 h-2 rounded w-2/3"></div>;
    }
    

}

const ListLikes = ({ likes, action }) => {
    const {t} = useTranslation()

    return (
        <div className="w-full h-full bg-black bg-opacity-50 fixed top-0 left-0 flex flex-col justify-center items-center">
            <div className="w-11/12 md:w-auto bg-white rounded border">
                <header className="flex flex-row w-full border-b items-center justify-between px-4">
                    <h1 className="font-bold py-2">{t("msg.liked")}</h1>
                    <span className="cursor-pointer"><CloseIcon onClick={()=>action()} /></span>
                </header>
                <section className="flex flex-col divide-y">
                    {likes? likes.map(user =>{
                        return (
                            <Link key={user} to={"/"+user}>
                                <div className="flex items-center gap-4 p-4">
                                    <ImgProfileFromAddress address={user} />
                                    <div className="flex flex-col overflow-hidden">
                                        {/* dark:text-slate-200 */}
                                        <strong className="text-slate-900 text-sm font-medium "><Username address={user}/></strong>
                                        {/* dark:text-slate-400 */}
                                        <span className="text-slate-500 text-sm font-medium">{user}</span>
                                    </div>
                                </div>
                            </Link>
                        )
                    }): ""}
                </section>
            </div>
        </div>
    );
}

export default ListLikes;