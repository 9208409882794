import { useEffect, useState } from "react";

const Web3 = require("web3");

const TimeStamp = ({block}) => {
    const [timestamp, setTimesamp] = useState();

    function printTime(timestamp){
        const dateObject = new Date(timestamp * 1000)
        const humanDateFormat = dateObject.toLocaleString() //2019-12-9 10:30:15
        return humanDateFormat;
    }

    useEffect(() => {
        async function getTimeStamp(){
            const web3 = new Web3(window.ethereum);
            const data = await web3.eth.getBlock(block);
            setTimesamp(data.timestamp)
        }

        getTimeStamp();
    
      return () => {};
    }, [block]);

    if(timestamp){
        return (
            <>{printTime(timestamp)}</>
        );
    } else {
        return "";
    }
}

export default TimeStamp;