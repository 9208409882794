import { createContext, useCallback, useContext, useEffect, useState } from "react";
import EthereumContext from "../ethereum/ethereum";
import UserContext from "../user/user-context";
import PostController from "./PostController";

const PostContext = createContext({
  posts : [],
  loadPosts : () => {}
});

export function PostContextProvider(props) {
  const [posts, setPosts] = useState([]);
  const ethereumCtx = useContext(EthereumContext);
  const userCtx = useContext(UserContext);

  const loadPosts = useCallback (async () => {
    setPosts(await new PostController().getPostsFromOwner(userCtx.user))
  })

  useEffect(() => {
    if (userCtx.user !== "") {
      loadPosts();
    }
    return () => {};
  }, [userCtx]);
  

  const context = {
    posts: posts,
    loadPosts: loadPosts
  };



  return (
    <PostContext.Provider value={context}>
      {props.children}
    </PostContext.Provider>
  );  
}

export default PostContext;

  // function addFavoriteHandler() {}et
  // function removeFavoriteHandler() {}
  // function itemIsFavoriteHandler() {}