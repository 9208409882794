const translation = {
    'sitename': 'Sochain',
    'home': {
        'title': 'Welcome to Polygon ETH',
        'subtitle': ' Polygon Network',
    },
    'profile': {
        title: "Profile"
    },
    'publish': {
        title: "Publish"
    },
    'wallet': {
        title: "Wallet"
    },
    menu: {
        'home': 'Home',
        'categories': 'Categories',
        'profile': 'Profile'
    },
    routes: {
        'home': '/',
        'categories': '/categories',
        'profile': '/account',
        'shop': '/marketplace-nfts',
        'addPost': '/pubblica',
        'wallet': '/wallet',
        'steps': '/steps-for-use-sochain',
        'editProfile': '/edit-profile',
        'likes': '/likes',
    },
    msg: {
        "altri": "and others",
        "balance": "Balance",
        "askFounds": "Ask founds on",
        "writePost": "Write your post",
        "send": "Send",
        "liked": "Likes",
        traits: "Traits"
    }
}

export default translation;